<template>
    <div class="body d-flex py-lg-3 py-md-2">
        <div class="container-xxl">
            <div class="row clearfix">
                <div class="col-md-12">
                    <div class="card border-0 mb-2 no-bg">
                        
                        <div class="card-header mt-3 mt-xl-0 py-1 px-0 d-flex align-items-center justify-content-end main-button-area">
                            <!-- <h3 class="flex-fill mb-0 mt-sm-0 fw-bold">Προσφορές</h3> -->
                            <!-- Green Circle -->
                            <div class="circle green-circle" @click="fetchData({ published: 1 })" style="cursor: pointer;"></div>
  
                            <!-- Red Circle -->
                            <div class="circle red-circle mx-3" @click="fetchData({ published: 0 })" style="cursor: pointer;"></div>
                            <!-- Button to Create New Offer -->
                            <button type="button" class="btn btn-primary" @click="createNewOffer" style="margin-right:15px">
                                <i class="icofont-plus text-white" ></i>Nέα προσφορά
                            </button>

                            <div class="rounded p-1 me-2" style="background-color: #C8C8C8;cursor: pointer">
                                <img @click="fetchData()" src="../assets/images/reload.png" alt="" width="30">
                            </div>
                            
                            <!-- <i class="icofont-refresh active-svg" style="margin-right:15px"
                            
                                
                            </i>   -->
                            
                            <i class="icofont-listine-dots me-2" style="color:#BDBDBD"
                                :class="{ 'active-svg text-white me-2': !isGridView}"
                                @click="isGridView = false">
                            </i>                           
                            <svg
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                :class="{'active-svg': isGridView}"
                                @click="isGridView = true"
                            >
                                <path 
                                    d="M13.133 4H4v9.333h9.333V4ZM28 4h-9.333v9.333H28V4ZM28 18.667h-9.333V28H28v-9.333ZM13.333 18.667H4V28h9.333v-9.333Z" 
                                    :stroke="isGridView ? '#fff' : '#BDBDBD'" 
                                    stroke-width="1.5" 
                                    stroke-linecap="round" 
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>

            

            <!-- Bootstrap Modal for Creating Offer -->
            <div class="modal fade" id="createOfferModal" tabindex="-1" aria-labelledby="createOfferModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        
                        <div class="modal-body">
                            <form @submit.prevent="submitCreateOffer">
                                <div class="row">
                                    <div class="col-md-4 mb-3">
                                        <label for="descr" class="form-label">Τίτλος </label>
                                        <input v-model="offerForm.descr" type="text" class="form-control" id="descr" required>
                                    </div>

                                    <div class="col-md-4 mb-3">
                                        <label for="startdate" class="form-label">Ημ/νια έναρξης</label>
                                        <input v-model="offerForm.startdate" type="date" class="form-control" id="startdate" required>
                                    </div>
                                    <div class="col-md-4 mb-3">
                                        <label for="enddate" class="form-label">Ημ/νια λήξης</label>
                                        <input v-model="offerForm.enddate" type="date" class="form-control" id="enddate" required>
                                    </div>

                                    <div class="col-md-6 mb-3">
                                        <label for="offer_category_ids" class="form-label">Κατηγορία προσφοράς</label>
                                        <select v-model="offerForm.offer_category_id" class="form-select" id="offer_category_id" required>
                                            <option v-for="category in categories" :key="category.id" :value="category.id">{{ category.title }}</option>
                                        </select>
                                    </div>

                                    <div class="col-md-6 mb-3">
                                        <label for="offer_img" class="form-label">Φωτογραφία</label>
                                        <div class="d-flex align-items-center">
                                            <img v-if="offerForm.offer_img && typeof offerForm.offer_img.indexOf == 'function' && offerForm.offer_img.indexOf('uploads') !== -1" :src="`/storage/${offerForm.offer_img}`"  width="38" height="38"/>
                                            <input @change="handleFileUpload" type="file" class="form-control" id="offer_img" style="margin-left: 10px;"/>
                                        </div>
                                        <div class="form-check mt-2" v-if="offerForm.offer_img && typeof offerForm.offer_img.indexOf == 'function' && offerForm.offer_img.indexOf('uploads') !== -1">
                                            <input v-model="offerForm.delete_img" class="form-check-input" type="checkbox" id="delete_img">
                                            <label class="form-check-label" for="delete_img">
                                                Διαγραφή εικόνας
                                            </label>
                                        </div>
                                    </div>
                                    
                                    <div class="col-md-12 mb-3">
                                        <label for="offertext" class="form-label">Περιγραφή προσφοράς</label>
                                        <textarea v-model="offerForm.offertext" class="form-control" id="offertext" rows="3" required></textarea>
                                    </div>
                                    
                                    <div class="col-md-6 mb-3">
                                        <label for="publishdate" class="form-label">Ημ/νια δημοσίευσης</label>
                                        <input v-model="offerForm.publishdate" type="date" class="form-control" id="publishdate" required>
                                    </div>
                                    
                                    <div class="col-md-6 mb-3">
                                        <label for="status" class="form-label">Δημοσιευμένη</label>
                                        <select v-model="offerForm.status" class="form-select" id="status" required>
                                            <option value="1">ΝΑΙ</option>
                                            <option value="0">ΟΧΙ</option>
                                        </select>
                                    </div>
                                   
                                </div>
                                <button type="submit" class="align-items-end justify-content-end btn btn-primary">Υποβολή</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Bootstrap Modal for Creating Offer -->
            <div class="modal fade" id="deleteOfferModal" tabindex="-1" aria-labelledby="deleteOfferModal" aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        
                        <div class="modal-body">
                            <form @submit.prevent="submitDeleteOffer">
                                <div class="row">
                                    Είστε σίγουροι ότι θέλετε να διαγράψετε την προσφορά;

                                </div><br>
                                <button type="submit" class="align-items-end justify-content-end btn btn-danger" style="margin-left:80%">Διαγραφή</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>


            <!-- Conditional Rendering for Views -->
            <h1 class="page-title  d-none">Προσφορές</h1>
            <div v-if="isGridView" class="row justify-content-center g-xl-5 g-3 row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2 row-cols-xl-4 row-cols-xxl-4 row-deck pt-4 pb-4" style="position: relative; z-index: 0">
                <div class="col-11 col-xl-6" v-for="(offerData, index) in data" :key="offerData.id">
                    <div class="card">
                        <div class="card-body d-flex align-items-center rounded" :style="{ borderLeft: offerData.status === 1 ? '6px solid green' : '6px solid red' }" style="background: #EDEDED !important;">
                            <!-- Content Container -->
                            <div class="flex-grow-1 ms-xl-3 ms-0 user-info" style="display: flex; flex-direction: column;">
                                <h5 class="mb-0 fw-bold d-block fs-6">{{ offerData.descr }}</h5>
                                <span class="rounded-1 d-inline-block small-14 mb-0 mt-1">Hμ/νια: <b>{{ offerData.publishdate }}</b></span><br>
                                <div class="d-flex align-items-center mb-2">
                                    <i class="icofont-calendar"></i>
                                    <span class="ms-2">{{ offerData.startdate }}</span>
                                </div>
                                <div class="d-flex align-items-center">
                                    <i class="icofont-calendar"></i>
                                    <span class="ms-2">{{ offerData.enddate }}</span>
                                </div><br>
                                <span v-if="offerData.status === 1" class="text-success fw-bold mt-auto">ΔΗΜΟΣΙΕΥΜΕΝΗ</span>
                                <span v-else class="text-danger fw-bold mt-auto">ΜΗ ΔΗΜΟΣΙΕΥΜΕΝΗ</span>
                            </div>
                            <!-- Button Container -->
                            <div class="d-flex flex-column align-items-end ms-3" style="position:absolute;right:-15px">
                                <router-link :to="{ path: 'offer-profile', query: { id: offerData?.id } }" class="btn border-0 p-1 btn-primary mb-2">
                                    <!-- <i class="icofont-eye text-white"></i> -->
                                    <img src="../assets/images/eye-w.png" alt="" width="30">
                                </router-link>
                                <button @click="edit(offerData)" class="btn border-0 p-1 btn-primary mb-2 bg-green">
                                    <!-- <i class="icofont-edit text-white"></i> -->
                                    <img src="../assets/images/edit.png" alt="" width="30">
                                </button>
                                <button @click="del(offerData)" class="btn border-0 p-1 btn-primary bg-danger">
                                    <!-- <i class="icofont-ui-delete text-white"></i> -->
                                    <img src="../assets/images/trash.png" alt="" width="30">
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col" v-if="!data || !data.length">
                    <p>Δεν βρεθηκαν αποτελέσματα...</p>
                </div>
            </div>

            <div v-if="!isGridView" class="row clearfix g-3">
                <div class="col-lg-12">
                    <div class="mb-3">
                        <div class="card table-responsive">
                            <table id="clients" class="table align-middle mb-0" style="width:100%">
                                <thead>
                                    <tr>
                                        <th style="width:20%; text-align: center;">ΤΙΤΛΟΣ</th>
                                        <th style="width:15%; text-align: center;">ΗΜ/ΝΙΑ ΕΝΑΡΞΗΣ</th>
                                        <th style="width:20%; text-align: center;">ΗΜ/ΝΙΑ ΛΗΞΗΣ</th>
                                        <th style="width:20%; text-align: center;">ΗΜ/ΝΙΑ ΔΗΜΟΣΙΕΥΣΗΣ</th>
                                        <th style="width:20%; text-align: center;">ΔΗΜΟΣΙΕΥΜΕΝΗ</th>
                                        <th style="width:15%;"></th> <!-- Added column for action buttons -->
                                    </tr>
                                </thead>
                                <tbody id="clients-body">
                                    <tr v-for="(offerData, index) in data" :key="offerData.id">
                                        <td>{{ offerData.descr }}</td>
                                        <td>{{ offerData.startdate }}</td>
                                        <td>{{ offerData.enddate }}</td>
                                        <td>{{ offerData.publishdate }}</td>
                                        <td>
                                            <span v-if="offerData.status === 1" class="text-success">ΝΑΙ</span>
                                            <span v-else class="text-danger">ΟΧΙ</span>
                                        </td>
                                        <td>
                                            <div class="d-flex align-items-center gap-2">
                                                <router-link :to="{ path: 'offer-profile', query: { id: offerData?.id } }" class="border-0 btn p-0 btn-primary" style="text-decoration: none;">
                                                    <!-- <i class="icofont-eye text-white"></i>
                                                    <img src="" alt=""> -->
                                                    <img src="../assets/images/eye-w.png" alt="" width="30">
                                                </router-link>

                                                <button @click="edit(offerData)" class="btn p-0 btn-primary border-0 bg-green">
                                                    <!-- <i class="icofont-edit text-white"></i> -->
                                                    <img src="../assets/images/edit.png" alt="" width="30">
                                                </button>
                                                <button @click="del(offerData)" class="btn p-0 btn-primary border-0 bg-danger">
                                                    <!-- <i class="icofont-ui-delete text-white"></i> -->
                                                    <img src="../assets/images/trash.png" alt="" width="30">
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr v-if="!data || !data.length">
                                        <td colspan="6" class="text-center">No data available</td> <!-- Adjust colspan to match number of columns -->
                                    </tr>
                                </tbody>
                            </table>
                            <nav aria-label="Page navigation">
                                <ul class="pagination mt-4" v-if="paginationLinks && paginationLinks.length > 0">
                                    <li class="page-item" v-for="(paginationLink, index) in paginationLinks" :key="index" :class="{ active: paginationLink.active }">
                                        <router-link
                                            :to="{ path: 'all-business-partner-requests', query: { ...filters, page: getPageFromUrl(paginationLink.url) } }"
                                            v-if="paginationLink.url">
                                            <span class="page-link" v-html="paginationLink.label"></span>
                                        </router-link>
                                        <span class="page-link" v-else v-html="paginationLink.label"></span>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>

            <nav aria-label="Page navigation" v-if="isGridView">
                <ul class="pagination mt-4" v-if="paginationLinks && paginationLinks.length > 0">
                    <li class="page-item" v-for="(paginationLink, index) in paginationLinks" :key="index" :class="{ active: paginationLink.active }">
                        <router-link :to="{ path: 'users', query: { ...filters, page: getPageFromUrl(paginationLink.url) } }" v-if="paginationLink.url">
                            <span class="page-link" v-html="paginationLink.label"></span>
                        </router-link>
                        <span class="page-link" v-else v-html="paginationLink.label"></span>
                    </li>
                </ul>
            </nav>

        </div>
    </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useFetchData } from '@/composables/useFetchData';
import { fetchAll, createOrUpdateOffer, deleteOffer, getOfferCategories, get } from '@/services/offers';
import 'vue-toastification/dist/index.css';
import { useToast } from 'vue-toastification';

export default {
    name: 'Offers',
    data() {
        return {
            isGridView: false, // true for grid view, false for list view
        };
    },
    setup() {
        const route = useRoute();
        const router = useRouter();
        const { data, paginationLinks, filters, updateFilters, fetchData, sortBy } = useFetchData(fetchAll, { page: route.query.page || 1 }, { page: 1 });

        const offerForm = ref({
            id: null,
            descr: '',
            price: '',
            offertext: '',
            startdate: '',
            enddate: '',
            publishdate: '',
            offer_category_id: null,
            status: 1,
            offer_img: null,
            delete_img: false  // Track if the user wants to delete the image
        });

        const categories = ref([]); // Example category data, replace with API call

        const handleFileUpload = (event) => {
            const file = event.target.files[0];
            offerForm.value.offer_img = file;  // Store the file directly in offerForm
        };

        const submitCreateOffer = async () => {
            const formData = new FormData();

            // Append all fields to FormData, including the file
            formData.append('id', offerForm.value.id);
            formData.append('descr', offerForm.value.descr);
            formData.append('price', offerForm.value.price);
            formData.append('offertext', offerForm.value.offertext);
            formData.append('startdate', offerForm.value.startdate);
            formData.append('enddate', offerForm.value.enddate);
            formData.append('publishdate', offerForm.value.publishdate);
            formData.append('status', offerForm.value.status);
            formData.append('offer_category_id', offerForm.value.offer_category_id);

            

            if (offerForm.value.offer_img && offerForm.value.delete_img != true) {
                // Add the file (only if one exists and not marked for deletion)
                formData.append('delete_img', false);
                formData.append('offer_img', offerForm.value.offer_img);
            } else if (offerForm.value.delete_img) {
                formData.append('delete_img', true);
            }

            // Now, submit the form using FormData
            const response = await createOrUpdateOffer(formData);
            
            offerForm.value.offer_img = null;
            offerForm.value.delete_img = false;

            if (response.data.success) {
                $('#createOfferModal').modal('toggle');
                $("#offer_img").val('').trigger('change');
                useToast().success(response.data.message);
                fetchData();
            } else {
                useToast().error(response.data.message);
            }
        };

        const submitDeleteOffer = async () => {
       
            // Now, submit the form using FormData
            const response = await deleteOffer(offerForm.value.id);
            
            if (response.data.success) {
                $('#deleteOfferModal').modal('toggle');
                useToast().success(response.data.message);
                fetchData();
            } else {
                useToast().error(response.data.message);
            }
        };

        const getPageFromUrl = (url) => {
            if (!url) return null;
            const urlObj = new URL(url, window.location.origin);
            return urlObj.searchParams.get('page');
        };

        const searchAction = () => {
            setTimeout(() => {
                router.push({ path: '/offers', query: { ...route.query, search: $("#main-search-input").val() } });
            }, 300);
        };

        onMounted(async () => {
            $(document.body).on('keyup', "#main-search-input", searchAction).on('click', "#main-search-input", searchAction);
            const r = await getOfferCategories();
            if (r.data.success) {
                categories.value = r.data.data;
            }
        });

        const createNewOffer = () => {
            offerForm.value = {
                id: null,
                descr: '',
                price: '',
                offertext: '',
                startdate: '',
                enddate: '',
                publishdate: '',
                offer_category_id: null,
                status: 1,
                offer_img: null,
                delete_img: false  // Track if the user wants to delete the image
            };
            $('#createOfferModal').modal('toggle');
        }

        onUnmounted(() => {
            $(document.body).off('keyup', "#main-search-input", searchAction).off('click', "#main-search-input", searchAction);
        });

        const edit = async (offerData) => {
            const r = await get({id: offerData.id});
            if (r.data.success) {
                offerForm.value = {
                    id: r.data.data.id,
                    descr: r.data.data.descr,
                    price: r.data.data.price,
                    offertext: r.data.data.offertext,
                    startdate: r.data.data.startdate,
                    enddate: r.data.data.enddate,
                    publishdate: r.data.data.publishdate,
                    delete_img: false,
                    offer_category_id: r.data.data.offer_category_id,
                    status: r.data.data.status,
                    offer_img: r.data.data.offer_img,
                };
            }
            $("#createOfferModal").modal('toggle');
        };

        const del = async (offerData) => {
            const r = await get({id: offerData.id});
            if (r.data.success) {
                offerForm.value = {
                    id: r.data.data.id,
                };
            }
            $("#deleteOfferModal").modal('toggle');
        };

        return {
            data, paginationLinks, filters, updateFilters, fetchData, getPageFromUrl, sortBy, edit, del,
            offerForm, submitCreateOffer, categories, handleFileUpload, createNewOffer, deleteOffer, submitDeleteOffer
        };
    },
    metaInfo: {
        title: 'Προσφορές',
        meta: [
            { name: 'description', content: 'Προσφορές' },
            { property: 'og:title', content: 'Προσφορές' },
            { property: 'og:description', content: 'Προσφορές' }
        ]
    }
};
</script>

<style scoped>
    /* Add lines below each header */
    th {
        position: relative;
        text-align: center;
        padding-bottom: 15px;
        /* Adds some space between the text and the line */

    }

    th:not(:last-child)::after {
        content: "";
        display: block;
        width: calc(100% - 10px);
        /* Line width exactly under the header, with a small margin */
        height: 3px;
        background-color: rgb(171, 169, 169);
        position: absolute;
        bottom: 0;
        left: 5px;
        /* Center the line with margin on both sides */
    }

    #createOfferModal {
        margin-top: 5%;
    }

    #deleteOfferModal {
        margin-top: 25%;
    }

    .modal-content {
        border-radius: 50px;

    }

    .active-svg {
        background-color: #d1d1d1;
    }

    .icofont-listine-dots,
    .icofont-refresh,
    svg {
        width: 35px;
        /* Match the SVG width */
        height: 35px;
        /* Match the SVG height */
        font-size: 24px;
        /* Adjust font-size to scale the icon properly */
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        /* Optional: For rounded corners like the SVG */
        cursor: pointer;
    }

    .row g-3 {
        display: none;
    }

    .circle {
        width: 20px;
        height: 20px;
        border-radius: 50%;
    }

    .green-circle {
        background-color: green;
    }

    .red-circle {
        background-color: red;
    }

    .bg-green {
        background-color: #A2C28F !important;
    }

    table,
    thead,
    th {
        border: none !important;
    }

    table tr td {
        border: 0px;
    }

    table {
        border-collapse: separate !important;
        border-spacing: 0px 15px;
    }

    .table-responsive {
        border: none !important;
    }

    #clients-body tr td {
        border-style: solid;
        border-width: 0px;
        background: #F2F2F2;
        text-align: center
    }

    table td:first-of-type {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }

    table td:nth-last-of-type(2) {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    table td:last-of-type {
        background: #ffffff !important;
    }

    @media (max-width: 768px) {
        .w-mob-100 {
            width: 100%;
        }

        .flex-column-mobile {
            display: flex;
            flex-direction: column
        }
        table td:last-of-type{
            position: sticky;
            right: 0px;
            box-shadow: -10px 0px 10px -5px #dedede;
        }
    }
</style>
