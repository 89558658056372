
<template>
  <div class="store-info-container">
    <!-- Header with Title and Logo -->
    <header
      class="header px-xl-5 px-3"
      :style="{ backgroundImage: `url(${storeData.backgroundImageUrl ? '/storage/' + storeData.backgroundImageUrl : require('@/assets/images/marketplace-banner.jpg')})` }"
    >
    <div class="d-flex flex-column align-items-center">
      <div class="logo-section d-flex align-items-center gap-3">
        <div>
          <RouterLink to="/">
            <img v-if="storeData.logoUrl" :src="`/storage/LOGOS/${storeData.logoUrl}`" height="110" />
          </RouterLink>
        </div>
        <div>
          <div class="titles">
            <!-- Use titleColor reactive property to change color dynamically -->
            <h1
              class="main-title fw-bold fs-2"
              :style="{ color: titleColor }"
            >
              {{ storeData.marketplaceTitle }}
            </h1>
            <h2
              class="sub-title mb-0 fs-3"
              :style="{ color: titleColor }"
            >
              {{ storeData.subTitle }}
            </h2>
          </div>
        </div>
      </div>
      <div class="mt-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb bg-transparent p-0 d-flex align-items-baseline">
            <li class="breadcrumb-item ">
              <router-link class="color-600" to="/" :style="{ color: titleColor }">Αρχική</router-link>
            </li>
            <li class="breadcrumb-item" aria-current="page">
              <router-link :to="`/category/${categoryId}/${categorySlug}`" class="color-600" :style="{ color: titleColor }"><b>{{categoryTitle}}</b></router-link>
            </li>
            <li class="breadcrumb-item active" :style="{ color: titleColor }" aria-current="page">
              <b>{{ storeData.brandname }}</b>
            </li>
          </ol>
      </nav>
      </div>
    </div>
      
      
      <!-- Rounded Image (newly added) -->
      <div class="rounded-image-container">
        <img
          :src="storeLogoImageUrl"
          alt=""
          class="rounded-image"
        />
        <!-- <img
          v-if="storeData.storeLogoUrl"
          :src="`/storage/${storeData.storeLogoUrl}`"
          alt=""
          class="rounded-image"
        /> -->
      </div>
    </header>

    <!-- Tab Navigation Links -->
    <nav class="nav-section">
      <button @click="activeTab = 'offers'" :class="{ active: activeTab === 'offers' }"
        class="nav-link">ΠΡΟΣΦΟΡΕΣ</button>
      <button @click="activeTab = 'info'" :class="{ active: activeTab === 'info' }"
        class="nav-link">ΠΛΗΡΟΦΟΡΙΕΣ</button>
    </nav>

    <!-- Content Based on Active Tab -->
    <div class="tab-content mb-xl-5">
      <!-- Information Section (with layout split in left and right) -->
      <div v-if="activeTab === 'info'" class="info-layout mt-3 px-xl-5">
        <div class="left-section">
          <!-- Left Section: Business Information (Category, Address, Phones) -->
          <div class="business-info">
            <div>
              <p>{{ storeData.business_descr }}</p>
            </div>
            <div class="d-flex align-items-center gap-2">
              <img src="../assets/images/category.png" alt="" width="30">
              <div class="d-flex flex-column">
                <h5 class="category mb-0">{{ storeData.category }}</h5>
                <span class="mb-0 color-600">{{ storeData.Subcategory }}</span>
              </div>
            </div>
            <div class="d-flex align-items-center gap-2">
              <img src="../assets/images/map.png" alt="" width="30">
              <address class="address mb-0">{{ storeData.address }} | T.K: {{ storeData.Zipcode }}</address>
            </div>
            <div class="d-flex align-items-center gap-2">
              <img src="../assets/images/Phone.png" alt="" width="30">
              <span class="phone">{{ storeData.phone1 }}</span>
            </div>
            <div class="d-flex align-items-center gap-2">
                <img src="../assets/images/Phone.png" alt="" width="30">
                <span class="phone">{{ storeData.phone2 }}</span>
            </div>
          </div>
          <div class="social-icons">
            <a :href="storeData.webpageUrl" class="social-icon">
              <img src="../assets/images/internet.png" alt="" width="30">
            </a>
            <a :href="storeData.instagramUrl" class="social-icon">
              <img src="../assets/images/instagram.png" alt="" width="30">
            </a>
            <a :href="storeData.facebookUrl" class="social-icon">
              <img src="../assets/images/facebook.png" alt="" width="30">
            </a>
          </div>
        </div>

        <div class="right-section">
          <!-- Right Section: Business Hours and Social Icons -->
          <div class="operating-hours">
            <h5 class="fw-bold">ΩΡΑΡΙΟ ΛΕΙΤΟΥΡΓΙΑΣ</h5>
            <div class="schedule" v-html="formattedText"></div>
          </div>
        </div>
      </div>

      <!-- Map Section at the bottom of the Info Tab -->
      <div class="map-section" v-if="activeTab === 'info' && storeData.latitude && storeData.longitude">
        <l-map :zoom="100" :center="[storeData.latitude, storeData.longitude]" style="height: 450px; width: 100%;">
          <l-tile-layer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution="&copy; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors" />
          <l-marker :lat-lng="[storeData.latitude, storeData.longitude]"></l-marker>
        </l-map>
      </div>

      <!-- Offers Section -->
      <div v-else-if="activeTab === 'offers'" class="offers-section mt-xl-3 mb-3 px-xl-5">
        <div class="offer-item" v-for="(offer, index) in storeData.offers" :key="index" @click="openOfferModal(offer)">
          <div 
            class="offer-image mb-0" 
            :style="{ backgroundImage: `url(${offer.offer_img ? '/storage/' + offer.offer_img : require('@/assets/images/marketplace-banner.jpg')})` }"
          ></div>
          <div class="d-flex justify-content-end justify-content-center-mobile me-xl-4">
            <button class="cstmBtn shadow">Περισσότερα</button>
          </div>
          
          <div class="offer-info px-2 pb-xl-2">
            <h3>{{ offer.descr }}</h3>
            <div class="d-flex justify-content-between justify-content-center-mobile">
              <div>
                <span v-if="isOfferExpired(offer.enddate)" class="offer-expired">Μη διαθέσιμη</span>
              </div>
            </div>
            <!-- <h3>{{ offer.offertext }}</h3> -->
            <!-- <span class="offer-discount">{{ offer.discount }}</span> -->
          </div>
          <div v-if="!isOfferExpired(offer.enddate)" class="dateContainer shadow">
              <span class="me-3">{{ formatDate(offer.startdate) }} έως {{ formatDate(offer.enddate) }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="offerModal" class="modal fade overflow-hidden" tabindex="-1" aria-labelledby="offerModalLabel" aria-hidden="true">
    <div class="modal-dialog h-100 d-flex flex-column justify-content-center ">
      <div class="modal-content" :style="{ backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.8)), url(${offerImageUrl})` }">
        <div class="modal-header">
          <h3 class="modal-title" id="offerModalLabel">{{ selectedOffer?.descr || '' }}</h3>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <!-- <img :src="offerImageUrl" alt="Offer image" class="img-fluid mb-3"> -->
          <p class="fs-6 fw-bold">{{ selectedOffer?.offertext }}</p>
          <span class="offer-discount">{{ selectedOffer?.discount }}</span>
        </div>
        <div class="modal-footer">
          <div class="d-flex">
            <div>
              <span class="me-xl-3 fw-bold"><span class="mx-2 fw-bold rounded py-1 px-3 smallBtn">Έναρξη</span>{{ formatDate(selectedOffer?.startdate) }}</span>
            </div>
            <div>
              <span class="me-xl-3 fw-bold"><span class="mx-2 py-1 fw-bold rounded px-3 smallBtn">Λήξη</span>{{ formatDate(selectedOffer?.enddate) }}</span>
            </div>

            
          </div>
          <!-- <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { RouterLink, useRoute } from 'vue-router';
import { LMap, LTileLayer, LMarker } from 'vue3-leaflet'; // Ensure correct imports
import { getBusinessPartnerInfo } from '@/services/business-partners'; // Ensure this path is correct
import 'leaflet/dist/leaflet.css'; // Ensure CSS import for map
import { useHead } from '@vueuse/head';
import { getBusinessCategory } from '@/services/category';

export default {
  name: 'StoreInfo',
  components: {
    LMap,
    LTileLayer,
    LMarker,
  },
  computed: {
    formattedText() {
      return this.storeData.operatingHours ? this.storeData.operatingHours.replace(/\n/g, "") : '';
    },
    storeLogoImageUrl() {
      // If storeLogoUrl exists, return the storage URL, otherwise return the default logo
      return this.storeData.storeLogoUrl
        ? `/storage/${this.storeData.storeLogoUrl}`
        : require('@/assets/images/marketplace-logo.jpg');
    },
    offerImageUrl() {
      // If offer_img exists, return the storage URL, otherwise return the default offer image
      return this.selectedOffer?.offer_img
        ? `/storage/${this.selectedOffer.offer_img}`
        : require('@/assets/images/marketplace-logo.jpg'); // Adjust path as necessary
    }
  },
  setup() {
    const loading = ref(true); // Loading state
    const storeData = ref({}); // Store data state
    const activeTab = ref('offers'); // Tab state (default is 'offers')
    const route = useRoute();  // Access the route object using useRoute
    const selectedOffer = ref(null);
    const titleColor = ref('#1d1d1d'); // Default color (dark)
    const categoryTitle = ref('');
    const categorySlug = ref('');
    const categoryId = ref('');

    const formatDate = (dateStr) => {
      if (!dateStr || !/^\d{4}-\d{2}-\d{2}$/.test(dateStr)) {
        return dateStr; // Return the original string if it's not in the correct format
      }
      const [year, month, day] = dateStr.split('-');
      return `${day}-${month}-${year}`;
    };

    const openOfferModal = (offer) => {

      selectedOffer.value = offer;
      $('#offerModal').modal('show'); // Show the Bootstrap modal using jQuery
    };

    // Check if the offer is expired
    const isOfferExpired = (dateStr) => {
      // Validate the date format coming from MySQL (optional)
      if (!/^\d{4}-\d{2}-\d{2}$/.test(dateStr)) {
        throw new Error("Invalid date format from MySQL. Expected format is YYYY-MM-DD.");
      }
      // Split the MySQL date string into year, month, and day components
      const [year, month, day] = dateStr.split('-').map(Number);
      // Create a new Date object (months are zero-based in JavaScript)
      const inputDate = new Date(year, month - 1, day);
      // Get the current date
      const currentDate = new Date();
      // Set the time of both dates to midnight to compare only the dates
      inputDate.setHours(0, 0, 0, 0);
      currentDate.setHours(0, 0, 0, 0);
      // Compare the input date with the current date
      return inputDate < currentDate;
    }

    const isImageDark = (url, callback) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = url;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0, img.width, img.height);
        const imageData = ctx.getImageData(0, 0, img.width, img.height);
        let colorSum = 0;

        for (let i = 0; i < imageData.data.length; i += 4) {
          const r = imageData.data[i];
          const g = imageData.data[i + 1];
          const b = imageData.data[i + 2];
          const avg = (r + g + b) / 3;
          colorSum += avg;
        }

        const brightness = colorSum / (img.width * img.height);
        callback(brightness < 128); // A threshold to determine darkness
      };
    };

    // Change text color based on the image brightness
    const adjustTextColor = () => {
      let imageUrl;

      // Check if there's a background image URL in storeData
      if (storeData.value.backgroundImageUrl) {
        imageUrl = `/storage/${storeData.value.backgroundImageUrl}`;
      } else {
        // Use the default image if no URL is found
        imageUrl = require('@/assets/images/marketplace-banner.jpg');
      }

      // Call the function to check if the image is dark
      isImageDark(imageUrl, (isDark) => {
        titleColor.value = isDark ? '#ffffff' : '#1d1d1d';
      });
    };

    // Example API call to fetch store data
    const fetchStoreData = async () => {
      try {
        const response = await getBusinessPartnerInfo({ id: route.params.id }); // API call
        const data = response.data || {}; // Ensure you are handling response correctly
        // Mock data structure or actual data
        storeData.value = {
          logoUrl: data.municipality?.image_file_name || null,
          marketplaceTitle: 'Marketplace',
          subTitle: data.municipality?.title || '-',
          backgroundImageUrl: data.businessPartner?.top_banner_img || '',
          storeLogoUrl: data.businessPartner?.Logo || '',
          webpageUrl: data.businessPartner?.Webpage_link || '#',
          category: (data.businessPartner.businessCategories) || '-',
          address: data.businessPartner?.Address || '-',
          brandname: data.businessPartner?.brandname || '-',
          phone1: data.businessPartner?.Phone || '-',
          phone2: data.businessPartner?.mobile || '-',
          instagramUrl: data.businessPartner?.instagram_link || '#',
          facebookUrl: data.businessPartner?.Facebook_link || '#',
          operatingHours: data.businessPartner?.open_hours || '',
          latitude: data.businessPartner?.Lat || null,
          longitude: data.businessPartner?.Lon || null,
          offers: data.offers || [],
          startdate: data.startdate?.startdate || '-',
          enddate: data.enddate?.enddate || '-',
          business_descr: data.businessPartner?.business_descr || '-',
          Zipcode: data.businessPartner?.Zipcode || '-',
          Subcategory: data.businessPartner?.Subcategory || '',
        };
        
        if (route.params.category_id) {
          const c = await getBusinessCategory(route.params.category_id);
          if (c.data.success) {
            categoryTitle.value = c.data.data.title;
            categorySlug.value = c.data.data.slug;
            categoryId.value = c.data.data.id;
          }
        }

        adjustTextColor();

        useHead({
          title: `Επιχειρήσεις - ${data.businessPartner?.brandname}`,
          meta: [
            { name: 'description', content: `Επιχειρήσεις - ${data.businessPartner?.brandname}` },
            { property: 'og:title', content: `Επιχειρήσεις - ${data.businessPartner?.brandname}` },
            { property: 'og:description', content: `Επιχειρήσεις - ${data.businessPartner?.brandname}` }
          ]
        });
        loading.value = false;  // Turn off loading once data is fetched
      } catch (error) {
        loading.value = false;  // Handle error and turn off loading
      }
    };

    // Fetch store data when the component is mounted
    onMounted(() => {

      fetchStoreData();
    });

    return {
      isOfferExpired,
      selectedOffer,
      openOfferModal,
      loading,
      storeData,
      activeTab,
      titleColor,
      categorySlug,
      categoryTitle,
      categoryId,
      formatDate,
    };
  },
};
</script>

<style scoped>
.header {
  min-height: 350px;
  display: flex;
  padding: 20px;
  background-color: #f7f7f7;
  background-position: center;
  /* Center the background image */
  background-repeat: no-repeat;
  /* Prevent the background from repeating */
  background-size: cover;
  /* Resize the background image to cover the entire element */
}
.dateContainer{
  position: relative;
  bottom: 80%;
  background: #ffffff8a;
  font-weight: bold;
  padding: 3px 15px;
  width: fit-content;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.rounded-image-container {
  position: absolute;
  z-index: 2;
  height: fit-content;
  top: 270px;
}
.cstmBtn{
  position: relative;
  bottom: 15px;
  border: 0px;
  padding: 4px 30px;
  background: #B9B9B9;
  color: #ffffff;
  font-weight: bold;
  border-radius: 30px;
  height: fit-content;
}
.smallBtn{
  background-color: #55555587;
  color: #fff;

}
.rounded-image {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  border: 2px solid #AB8A67;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  object-fit: contain;
  background: white;
}

.logo-section {
  height: fit-content;
}

.logo {
  width: 60px;
  height: auto;
  margin-right: 15px;
}

.titles {
  display: flex;
  flex-direction: column;
}

.main-title {
  font-size: 24px;
  margin: 0;
  color: #1d1d1d;
}

.sub-title {
  font-size: 18px;
  margin: 0;
  color: #888;
}

.nav-section {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.nav-link {
  font-size: 1.5rem;
  color: #181818;
  text-decoration: none;
  padding: 2px 20px;
  background: none;
  border: none;
  cursor: pointer;
}

.nav-link.active {
  font-weight: bold;
  border-bottom: 2px solid #AB8A67;
}

.tab-content {
  padding: 20px;
}

/* New layout for splitting left and right sections */
.info-layout {
  display: flex;
  justify-content: space-between;
}

.left-section {
  width: 45%;
}

.right-section {
  width: 45%;
}

/* Business Information and Operating Hours sections remain unchanged */
.business-info {
  font-size: 16px;
}

.operating-hours {
  font-size: 16px;
}

.social-icons {
  display: flex;
  margin-top: 20px;
}

.social-icon {
  font-size: 20px;
  color: #888;
  margin-right: 10px;
}

.hours-list {
  list-style: none;
  padding: 0;
}

.hours-list li {
  margin-bottom: 5px;
}

/* Offers Section styling */
.offers-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.offer-item {
  cursor: pointer;
  width: 32%;
  margin-bottom: 20px;
  border-radius: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 10px solid #ffffff;
}

.offer-image {
  height: 300px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-origin: content-box;
  border-radius: 30px;
  margin-bottom: 10px;
}

.offer-info h3 {
  font-size: 1.5rem;
  font-weight: bold;
}

.offer-info p {
  color: #888;
  margin: 5px 0;
}

.offer-discount {
  font-size: 18px;
  font-weight: bold;
  color: #d32f2f;
}
.offer-expired {
  font-weight: bold;
  color: #fff;
  padding: 4px 10px;
  background: #d32f2f;
  border-radius: 30px;
}

/* Map Section Styling */
.map-section {
  margin: 0 auto;
  margin-top: 30px;
  height: 450px;
  width: 95%;
}

.loading {
  font-size: 24px;
  text-align: center;
  margin-top: 50px;
  color: #555;
}

.schedule {
  white-space: pre-line;
}
.modal-content{
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.modal-title{
  font-weight: bold;
  text-shadow: 0 0 #181818;
}
.breadcrumb-item{
  font-size: 16px;
}
@media only screen and (max-width: 768px){
  .header{
    min-height: 280px;
    justify-content: center;
    z-index: 2;
  }
  .rounded-image-container{
    top: 210px;
  }
  .rounded-image{
    width: 140px;
    height: 140px;
  }
  .breadcrumb-item{
    font-size: 14px;
  }
  .nav-section{
    margin-top: 80px;
  }
  .nav-link{
    font-size: 1.25rem;
  }
  .offer-item{
    width: 100%;
  }
  .offer-image{
    height: 200px
  }
  .offer-info h3{
    font-size: 1.1rem;
    margin-bottom: 10px;
    text-align: center
  }
  .justify-content-center-mobile{
    justify-content: center !important;
  }
  .tab-content{
    padding: 0px 20px
  }
  .modal-content{
    margin: 0 auto;
    width: 90%;
  }
  .modal-body p{
    font-size: 1.1rem;
  }
  .modal-footer{
    justify-content: unset;
  }
  .info-layout {
    flex-direction: column;
    gap: 30px;
  }
  .left-section {
    width: 100%;
  }
  .right-section {
    width: 100%;
  }
  .map-section{
    width: 100%;
  }
}
</style>