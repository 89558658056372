<template>

    <div class="container-fluid px-4 px-xl-5 mt-3 mt-xl-0 mb-5">
        <!-- Header Section -->
        <div class="header-section mb-3">
            <h1 class="fw-bold mb-0 homeNumberOffers">200+</h1>
            <h1 class="fw-bold mb-0 homeOfferText">ΠΡΟΣΦΟΡΕΣ</h1>
            <h2 class="mb-0 color-700 homeOffersubText">ΜΟΝΟ ΓΙΑ ΕΣΕΝΑ!</h2>
            <p class="fs-6 color-600">Δες όλες τις προσφορές των καταστημάτων<br> μας και διάλεξε αυτές που εσύ θέλεις!</p>
        </div>
        <div class="row d-flex align-items-center text-end mb-4">
            <div class="col-12 col-xl-10">
                <hr>
            </div>
            <div class="col-12 col-xl-2">
                <h3 class="color-700 fs-3" style="letter-spacing: 2px;">Κατηγορίες</h3>
            </div>
        </div>
        <!-- Categories Section -->
        <div class="grid-container">
            <div class="grid-item item1">
                <div class="category-box" @click="goToCategory(5, 'athlisi')">
                    <img class="homeBanners" src="@/assets/images/marketplace-banners/bnr_athlisi.jpg" alt="ΑΘΛΗΣΗ">
                    <span class="category-label category-label-1">ΑΘΛΗΣΗ</span>
                </div>
                
            </div>
            <div class="grid-item item2">
                <div class="category-box" @click="goToCategory(19, 'vrefika_paidika')">
                    <img class="homeBanners" src="@/assets/images/marketplace-banners/bnr_vrefika_paidika.jpg" alt="ΒΡΕΦΙΚΑ ΠΑΙΔΙΚΑ">
                    <span class="category-label category-label-2">ΒΡΕΦΙΚΑ <span class="pt-5">ΠΑΙΔΙΚΑ</span></span>
                </div>
                
            </div>
            <div class="grid-item item3">
                <div class="category-box" @click="goToCategory(13, 'farmakeia')" style="background-color: #49AD39;">
                    <img class="category-label-3-img" src="@/assets/images/marketplace-banners/pharmacy.png" alt="ΦΑΡΜΑΚΕΙΑ" width="70">
                    <span class="category-label category-label-3">ΦΑΡΜΑΚΕΙΑ</span>
                </div>
                
            </div>
            <div class="grid-item item4">
                <div class="category-box" @click="goToCategory(7, 'texnologia')">
                    <img class="homeBanners" src="@/assets/images/marketplace-banners/bnr_katastimata_texnologias.jpg" alt="ΤΕΧΝΟΛΟΓΙΑ">
                    <span class="category-label category-label-18 color-700">ΚΑΤΑΣΤΗΜΑΤΑ ΤΕΧΝΟΛΟΓΙΑΣ</span>
                </div>
                
            </div>
            <div class="grid-item item5">
                <div class="category-box" @click="goToCategory(6, 'asfalistikes_yphresies')" style="background-color: #3DC8F4;">
                    <!-- <img class="homeBanners" src="@/assets/images/marketplace-banners/brn_asfalistikes_yphresies.jpg" alt="ΑΣΦΑΛΙΣΤΙΚΕΣ ΥΠΗΡΕΣΙΕΣ"> -->
                    <span class="category-label category-label-4">ΑΣΦΑΛΙΣΤΙΚΕΣ ΥΠΗΡΕΣΙΕΣ</span>
                </div>
                
            </div>
            <div class="grid-item item6">
                <div class="category-box" @click="goToCategory(21, 'gamos_vaptisi')">
                    <img class="homeBanners" src="@/assets/images/marketplace-banners/bnr_gamos.jpg" alt="ΓΑΜΟΣ ΒΑΠΤΙΣΗ">
                    <span class="category-label category-label-5 color-700">ΓΑΜΟΣ - ΒΑΠΤΙΣΗ</span>
                </div>
                
            </div>
            <div class="grid-item item7">
                <div class="category-box" @click="goToCategory(16, 'diakosmisi')">
                    <img class="homeBanners" src="@/assets/images/marketplace-banners/bnr_eidh_diakosmisis.jpg" alt="ΔΙΑΚΟΣΜΗΣΗ">
                    <span class="category-label category-label-7">ΕΙΔΗ ΔΙΑΚΟΣΜΗΣΗΣ</span>
                </div>
                
            </div>
            <div class="grid-item item8">
                <div class="category-box" @click="goToCategory(10, 'ygeia-omorfia')">
                    <img class="homeBanners" src="@/assets/images/marketplace-banners/bnr_ygeia-omorfia.jpg" alt="ΕΝΔΥΣΗ">
                    <span class="category-label category-label-8">ΥΓΕΙΑ<span class="pt-5">& ΟΜΟΡΦΙΑ</span></span>
                    
                </div>
            </div>
            <div class="grid-item item9">
                <div class="category-box" @click="goToCategory(4, 'diaskedasi')" style="background-color: #E1D4AA;">
                    <!-- <img class="homeBanners" src="@/assets/images/marketplace-banners/bnr_diaskedasi.jpg" alt="ΔΙΑΣΚΕΔΑΣΗ"> -->
                    <span class="category-label category-label-6">ΔΙΑΣΚΕΔΑΣΗ</span>
                </div>
                
            </div>
            <div class="grid-item item10">
                <div class="category-box" @click="goToCategory(3, 'emporio')" style="background-color: #98D17B">
                    <!-- <img class="homeBanners" src="@/assets/images/marketplace-banners/bnr_emporeio.jpg" alt="ΕΜΠΟΡΙΟ"> -->
                    <span class="category-label category-label-9">ΕΜΠΟΡΙΟ</span>
                </div>
            </div>
            <div class="grid-item item11">
                <div class="category-box" @click="goToCategory(20, 'stromata_maxilaria')">
                    <img class="homeBanners" src="@/assets/images/marketplace-banners/brn_stromata_maxilaria.jpg" alt="ΣΤΡΩΜΑΤΑ & ΜΑΞΙΛΑΡΙΑ" style="filter: opacity(0.4);">
                    <img class="category-label-11-img" src="@/assets/images/marketplace-banners/pillow.png" alt="ΣΤΡΩΜΑΤΑ & ΜΑΞΙΛΑΡΙΑ" width="70">
                    <span class="category-label category-label-11">ΣΤΡΩΜΑΤΑ & ΜΑΞΙΛΑΡΙΑ</span>
                </div>
                
            </div>
            <div class="grid-item item12">
                <div class="category-box" @click="goToCategory(1, 'estiash')">
                    <img class="homeBanners" src="@/assets/images/marketplace-banners/brn_estiasi.jpg" alt="ΕΣΤΙΑΣΗ">
                    <!-- <span class="category-label">ΕΣΤΙΑΣΗ</span> -->
                </div>
            </div>
            <div class="grid-item item13">
                <div class="category-box" @click="goToCategory(14, 'iatroi')">
                    <img class="homeBanners" src="@/assets/images/marketplace-banners/bnr_iatroi.jpg" alt="ΙΑΤΡΟΙ">
                    <span class="category-label category-label-10">ΙΑΤΡΟΙ</span>
                </div>
                
            </div>
            <div class="grid-item item14">
                <div class="category-box" @click="goToCategory(15, 'epipla')">
                    <img class="homeBanners" src="@/assets/images/marketplace-banners/bnr_epipla.jpg" alt="ΕΠΙΠΛΑ">
                    <span class="category-label category-label-12">ΕΠΙΠΛΑ</span>
                </div>
                
            </div>
            <div class="grid-item item15">
                <div class="category-box" @click="goToCategory(2, 'endysh')" style="background-color: #E8B7FF;">
                    <!-- <img class="homeBanners" src="@/assets/images/marketplace-banners/bnr_endysh.jpg" alt="ΕΝΔΥΣΗ"> -->
                    <span class="category-label">ΕΝΔΥΣΗ</span>
                </div>
                
            </div>
            <div class="grid-item item16">
                <div class="category-box" @click="goToCategory(17, 'hotel_deco')">
                    <img class="homeBanners" src="@/assets/images/marketplace-banners/bnr_hotel_deco.jpg" alt="HOTEL DECO">
                    <span class="category-label category-label-13"><span class="category-label-13-hotel">HOTEL</span> DECO</span>
                </div>
                
            </div>
            <div class="grid-item item17">
                <div class="category-box" @click="goToCategory(18, 'leuka_eidh')" style="background-color: #EEEEEE;">
                    <!-- <img class="homeBanners" src="@/assets/images/marketplace-banners/bnr_leuka_eidh.jpg" alt="ΛΕΥΚΑ ΕΙΔΗ"> -->
                    <span class="category-label category-label-14">ΛΕΥΚΑ ΕΙΔΗ</span>
                </div>
                
            </div>
            <div class="grid-item item18">
                <div class="category-box" @click="goToCategory(8, 'texnika_katastimata')" style="background-color: #E6B952;">
                    <!-- <img class="homeBanners" src="@/assets/images/marketplace-banners/brn_texnika_katastimata.jpg" alt="ΤΕΧΝΙΚΑ ΚΑΤΑΣΤΗΜΑΤΑ"> -->
                    <span class="category-label">ΤΕΧΝΙΚΑ ΚΑΤΑΣΤΗΜΑΤΑ</span>
                </div>
            </div>
            <div class="grid-item item21">
                <div class="category-box" @click="goToCategory(12, 'ypodisi')">
                    <img class="homeBanners" src="@/assets/images/marketplace-banners/bnr_ypodisi.jpg" alt="ΥΠΟΔΥΣΗ">
                    <span class="category-label category-label-16">ΥΠΟΔΥΣΗ</span>
                </div>
            </div>
            <div class="grid-item item20">
                <div class="category-box" @click="goToCategory(9, 'xrimatooikonomikes_ipiresies')">
                    <img class="homeBanners" src="@/assets/images/marketplace-banners/bnr_xrimatooikonomikes-yphresies.jpg" alt="ΧΡΗΜΑΤΟΙΚΟΝΟΜΙΚΕΣ ΥΠΗΡΕΣΙΕΣ">
                    <span class="category-label category-label-17">ΧΡΗΜΑΤΟΙΚΟΝΟΜΙΚΕΣ ΥΠΗΡΕΣΙΕΣ</span>
                </div>
            </div>
            <div class="grid-item item19">
                <div class="category-box" @click="goToCategory(11, 'alles_yphresies')" style="background-color: #E3E031;">
                    <!-- <img class="homeBanners" src="@/assets/images/marketplace-banners/brn_asfalistikes_yphresies.jpg" alt="ΑΛΛΕΣ ΥΠΗΡΕΣΙΕΣ"> -->
                    <span class="category-label category-label-15">ΑΛΛΕΣ ΥΠΗΡΕΣΙΕΣ</span>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
import router from '@/router';
import { useHead } from '@vueuse/head';
import ApexCharts from 'apexcharts';
import { useToast } from 'vue-toastification';

export default {
    name: 'Home',
    data() {
        return {};
    },
    computed: {},
    methods: {
        goToCategory(id, cat) {
            this.$router.push(`/category/${id}/${cat}`);
        }
    },
    mounted() {
        useHead({
            title: 'Αρχική | Marketplace',
            meta: [
                { name: 'description', content: 'Αρχική' },
                { property: 'og:title', content: 'Αρχική' },
                { property: 'og:description', content: 'Αρχική' }
            ]
        });
    }
};
</script>

<style scoped>
    .navbar {
        padding: 15px;
    }
    .navbar-brand img {
        height: 50px;
    }
    .header-section {
        text-align: left;
    }
    .category-box {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        color: white;
        cursor: pointer;
        text-align: center;
        font-weight: bold;
        border-radius: 10px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s ease;
        position: relative;
    }

    .category-box:hover {
        transform: scale(1.05);
    }

    .row {
        margin-bottom: 15px;
    }

    .homeBanners {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
    }

    .category-label {
        position: absolute;
        z-index: 2;
        font-size: 1.8rem;
        font-weight: bold;
        max-width: 200px;
    }
    .category-label-1 {
        top: 30px
    }
    .category-label-2{
        writing-mode: vertical-lr;
        text-orientation: upright;
        text-shadow: 1px 0px #fff;
        right: 50px;
    }
    .category-label-3{
        bottom: 25%;
    }
    .category-label-3-img{
        border: 3px solid #fff;
        padding: 8px;
        border-radius: 100%;
    }
    .category-label-4{
        text-shadow: 1px 0px;
    }
    .category-label-5{
        text-align: left;
        bottom: 20px;
        left: 15px;
    }
    .category-label-6{
        color: #958961;
        text-shadow: 1px 0px;
    }
    .category-label-7{
        color: #A27C65;
        top:20px;
    }
    .category-label-8{
        writing-mode: vertical-lr;
        text-orientation: upright;
        right: 30px;
    }
    .category-label-9{
        text-shadow: 1px 0px;
        font-size: 3rem;
    }
    .category-label-10{
        color: #527EB1;
        text-shadow: 1px 0px;
        font-size: 3rem;
    }
    .category-label-11{
        color: #739198;
        text-shadow: 1px 0px;
    }
    .category-label-11-img{
        top: 30px;
        position: absolute;
    }
    .category-label-12{
        color: #7F7F7F;
        text-shadow: 1px 0px;
        font-size: 3rem;
        top: 45px;
    }
    .category-label-13{
        left: 20px;
        top: 60px;
        text-align:left;
        font-size: 3rem;
    }
    .category-label-13-hotel{
        text-shadow: -2px -2px 0 #fff, 2px -2px 0 #fff, -2px 2px 0 #fff, 2px 2px 0 #fff, 0px -2px 0 #fff, 0px 2px 0 #fff, -2px 0px 0 #fff, 2px 0px 0 #fff;
        letter-spacing: .3rem;
        font-size: 3rem;
        color:#D4B090;
    }
    .category-label-14{
        color: #777;
        text-shadow: 1px 0px;
    }
    .category-label-15{
        text-shadow: 1px 0px;
    }
    .category-label-16{
        top: 5px;
        text-shadow: 1px 0px;
        color: #fff;
        letter-spacing: 5px;
    }
    .category-label-17{
        color: #5C6167;
        text-align: left;
        left: 35px;
        font-size: 2.2rem;
        text-shadow: 1px 0px;
    }
    .homeNumberOffers{
        font-size: 6.5rem;
        line-height: 6rem;
        color: #8FBDE9;
    }
    .homeOfferText{
        color: #2776A0;
        font-size: 3rem;
    }
    .homeOffersubText{
        letter-spacing: 1px;
    }
    .grid-container {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-auto-rows: 150px;
        gap: 25px;
    }
    .grid-item {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .item1 {
        grid-column: span 1;
        grid-row: span 2;
    }
    .item2 {
        grid-column: span 1;
        grid-row: span 3;
    }
    .item3 {
        grid-column: span 1;
        grid-row: span 2;
    }
    .item4 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .item5 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .item6 {
        grid-column: span 1;
        grid-row: span 2;
    }
    .item7 {
        grid-column: span 1;
        grid-row: span 2;
    }
    .item8 {
        grid-column: span 1;
        grid-row: span 2;
    }
    .item9 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .item10 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .item11{
        grid-column: span 1;
        grid-row: span 2;
    }
    .item12 {
        grid-column: span 2;
        grid-row: span 2;
    }
    .item13 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .item14 {
        grid-column: span 1;
        grid-row: span 2;
    }
    .item15 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .item16 {
        grid-column: span 1;
        grid-row: span 2;
    }
    .item17 {
        grid-column: span 1;
        grid-row: span 2;
    }
    .item18 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .item18 {
        grid-column: span 1;
        grid-row: span 1;
    }
    .item19 {
        grid-column: span 1;
        grid-row: span 2;
    }
    .item20 {
        grid-column: span 2;
        grid-row: span 2;
    }
    .item21 {
        grid-column: span 1;
        grid-row: span 2;
    }
    @media only screen and (max-width: 768px){
        .homeNumberOffers{
            font-size: 4rem;
            line-height: 3rem;
        }
        .homeOfferText{
            font-size: 2rem;
        }
        .homeOffersubText{
            font-size: 1.5rem;
        }
        .category-label{
            font-size: 1.25rem;
            font-weight: bold;
            max-width: 150px;
            white-space: normal;
        }
        .item1 {
            grid-column: span 2;
            grid-row: span 2;
        }
        .item2 {
            grid-column: span 2;
            grid-row: span 1;
        }
        .item3 {
            grid-column: span 2;
            grid-row: span 1;
        }
        .item4 {
            grid-column: span 2;
            grid-row: span 2;
        }
        .item5 {
            grid-column: span 2;
            grid-row: span 1;
        }
        .item6 {
            grid-column: span 2;
            grid-row: span 1;
        }
        .item7 {
            grid-column: span 2;
            grid-row: span 2;
        }
        .item8 {
            grid-column: span 2;
            grid-row: span 2;
        }
        .item9 {
            grid-column: span 2;
            grid-row: span 1;
        }
        .item10 {
            grid-column: span 2;
            grid-row: span 1;
        }
        .item11{
            grid-column: span 4;
            grid-row: span 1;
        }
        .item12 {
            grid-column: span 4;
            grid-row: span 1;
        }
        .item13 {
            grid-column: span 2;
            grid-row: span 1;
        }
        .item14 {
            grid-column: span 2;
            grid-row: span 2;
        }
        .item15 {
            grid-column: span 2;
            grid-row: span 1;
        }
        .item16 {
            grid-column: span 2;
            grid-row: span 2;
        }
        .item17 {
            grid-column: span 2;
            grid-row: span 1;
        }
        .item18 {
            grid-column: span 2;
            grid-row: span 1;
        }
        .item19 {
            grid-column: span 4;
            grid-row: span 1;
        }
        .item20 {
            grid-column: span 4;
            grid-row: span 2;
        }
        .item21 {
            grid-column: span 4;
            grid-row: span 2;
        }
        .grid-container {
            gap: 15px;
        }
        .category-label-2{
            right: 25px;
            font-size: 13px !important;
            top: -25px;
        }
        .category-label-3-img{
            border: 2px solid #fff;
            padding: 7px;
            width: 50px;
            top: 25px;
            position: absolute;
        }
        .category-label-18{
            top: 40px;
        }
        .category-label-11-img{
            width: 50px;
            top: 10px;
        }
        .category-label-11{
            bottom: 10px;
        }
        .category-label-13-hotel{
            font-size: 2rem;
        }
    }
</style>
