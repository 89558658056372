<template>
    <div class="body d-flex pb-lg-3 pb-md-2">
        <div class="container-xxl">
            <h1 class="page-title  d-none">Λίστα επιχειρήσεων</h1>
            <div class="row mb-5 mb-xl-1 clearfix">
                <div class="col-lg-12">
                    <div class="mb-3">
                        <div class="card-body table-responsive p-0">
                            <table id="clients" class="table table-hover align-middle mb-0 business_table" style="width:100%">
                                <thead>
                                    <tr>
                                        <th style="width:7%;"><div>Address</div></th>
                                        <th style="width:9%;"><div>Υποκατηγορια</div></th>
                                        <th style="width:7%;"><div>Brand name</div></th>
                                        <th style="width:9%;"><div>Contact person</div></th>
                                        <th style="width:10%;"><div>Email</div></th>
                                        <th style="width:9%;"><div>Τηλ.</div></th>
                                        <th style="width:9%;"><div>Κινητο</div></th>
                                        <th style="width:5%;"><div>Τ.Κ</div></th>
                                        <th style="width:10%;"><div>Webpage</div></th>
                                        <th style="width:10%;"><div>Facebook</div></th>
                                        <th style="width:10%;"><div>Instagram</div></th>
                                        <th style="width:10%;"><div>ΑΦΜ</div></th>
                                    </tr>
                                </thead>
                                <tbody id="business-body">
                                    <tr v-for="(businessPartnersData, index) in data" :key="index">
                                        <td>
                                            {{ businessPartnersData.Address }}
                                        </td>
                                        <td>
                                          {{ Array.isArray(businessPartnersData.business_categories) ? businessPartnersData.business_categories.map(category => category.title).join(', ') : '' }}
                                        </td>
                                        <td>
                                            {{ businessPartnersData.brandname }}
                                        </td>
                                        <td>
                                            {{ businessPartnersData.Contactperson }}
                                        </td>
                                        <td>
                                          {{ businessPartnersData.email }}
                                        </td>
                                        <td>
                                          {{ businessPartnersData.Phone }}
                                        </td>
                                        <td>
                                          {{ businessPartnersData.mobile }}
                                        </td>
                                        <td>
                                          {{ businessPartnersData.Zipcode }}
                                        </td>
                                        <td>
                                          {{ businessPartnersData.Webpage_link }}
                                        </td>
                                        <td>
                                          {{ businessPartnersData.Facebook_link }}
                                        </td>
                                        <td>
                                          {{ businessPartnersData.instagram_link }}
                                        </td>
                                        <td>
                                          {{ businessPartnersData.Vatcode }}
                                        </td>
                                    </tr>
                                    <tr class="col" v-if="!data || !data.length">
                                        <td colspan="12" class="text-center">
                                            No data
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <nav aria-label="Page navigation">
                                <ul class="pagination mt-4" v-if="paginationLinks && paginationLinks.length > 0">
                                    <li class="page-item" v-for="(paginationLink, index) in paginationLinks"
                                        :key="index" :class="{ active: paginationLink.active }">
                                        <router-link
                                            :to="{ path: 'business-partners', query: { ...filters, page: getPageFromUrl(paginationLink.url) } }"
                                            v-if="paginationLink.url">
                                            <span class="page-link" v-html="paginationLink.label"></span>
                                        </router-link>
                                        <span class="page-link" v-else v-html="paginationLink.label"></span>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
           <!--  <div class="col" v-if="!data || !data.length">
                <p>Δεν βρεθηκαν αποτελέσματα...</p>
            </div> -->
        </div>
    </div>
  </template>
  
  
<script>
import { ref, onMounted, onUnmounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useFetchData } from '@/composables/useFetchData';
import { fetchAll, get } from '@/services/business-partners';
import 'vue-toastification/dist/index.css';

export default {
    name: 'BusinessPartners',
    setup() {
        const route = useRoute();
        const router = useRouter();

        const { data, paginationLinks, filters, updateFilters, fetchData, sortBy } = useFetchData(fetchAll, { page: route.query.page || 1 }, { page: 1 });
        
        const getPageFromUrl = (url) => {
            if (!url) return null;
            const urlObj = new URL(url, window.location.origin);
            return urlObj.searchParams.get('page');
        };

        const searchAction = () => {
            setTimeout(() => {
                router.push({ path: '/business-partners', query: { ...route.query, search: $("#main-search-input").val() } });
            }, 300);
        };

        onMounted(() => {
            $(document.body).on('keyup', "#main-search-input", searchAction).on('click', "#main-search-input", searchAction);
        });

        onUnmounted(() => {
            $(document.body).off('keyup', "#main-search-input", searchAction).off('click', "#main-search-input", searchAction);
        });

        return { data, paginationLinks, filters, updateFilters, fetchData, getPageFromUrl, sortBy };
    },
    metaInfo: {
        title: 'Λίστα επιχειρήσεων',
        meta: [
            { name: 'description', content: 'Λίστα επιχειρήσεων' },
            { property: 'og:title', content: 'Λίστα επιχειρήσεων' },
            { property: 'og:description', content: 'Λίστα επιχειρήσεων' }
        ]
    }
};
</script>
  
<style scoped>
.table-responsive {
    overflow-x: auto;
}

table,
thead,
th {
    border: none !important;
}

table {
    border-collapse: separate !important;
    border-spacing: 0px 15px;
}

.table-responsive {
    border: none !important;
}

#business-body tr td {
    border-style: solid;
    border-width: 0px;
    background: #F2F2F2;
    text-align: center;
    max-width: 90px;
    white-space: break-spaces;
    word-wrap: break-word;
    padding: 0.8rem 0.3rem;
    
}

table td:first-of-type {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

table td:nth-last-of-type(2) {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

table td:last-of-type {
    background: #F2F2F2 !important;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

th {
    position: relative;
    text-align: center;
    padding-bottom: 15px; /* Adds some space between the text and the line */

}

th::after {
    content: "";
    display: block;
    width: calc(100% - 10px); /* Line width exactly under the header, with a small margin */
    height: 3px;
    background-color: rgb(171, 169, 169);
    position: absolute;
    bottom: 0;
    left: 5px; /* Center the line with margin on both sides */
}

.pagination {
    display: flex;
    justify-content: center;
}

.page-item.active .page-link {
    background-color: #3498db;
    border-color: #3498db;
}

.page-link {
    color: #3498db;
    border: 1px solid #ddd;
}

.page-link:hover {
    color: #fff;
    background-color: #3498db;
    border-color: #3498db;
}
@media only screen and (max-width: 1200px){
    
}
</style>

  