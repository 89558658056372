<template>
  <div class="body d-flex pb-lg-5 py-md-2">
    <div class="container-xxl">
      <h1 class="fw-bold page-title d-none">Επεξεργασία Προφίλ</h1>
      <div class="row clearfix g-xl-3 mb-5 mb-xl-0">
        <div class="col-lg-12">
          <form @submit.prevent="saveForm">
            <!-- Form Fields -->
            <div class="row">
              <div class="col-md-12">
                <label for="brandname" class="form-label">Επωνυμία</label>
                <input type="text" class="form-control" v-model="formData.brandname" />
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-md-6">
                <label for="trademark" class="form-label">Νομική Επωνυμία</label>
                <input type="text" class="form-control" v-model="formData.trademark" />
              </div>
              <div class="col-md-6">
                <label for="Vatcode" class="form-label">ΑΦΜ</label>
                <input type="number" class="form-control" v-model="formData.Vatcode" />
              </div>
            </div>

            <!-- File Uploads with Delete Checkboxes -->
            <div class="row mt-2 d-flex align-items-end">
              <div class="col-md-4">
                <label for="logo" class="form-label">Logo</label>
                <div class="d-flex fileImg">
                  <img
                    v-if="formData.Logo && typeof formData.Logo.indexOf == 'function' && formData.Logo.indexOf('uploads') !== -1"
                    :src="`/storage/${formData.Logo}`" width="38" height="38" /><input type="file" class="form-control"
                    @change="handleFileChange('Logo', $event)" />
                </div>
                <div class="form-check">
                  <input type="checkbox" class="form-check-input" v-model="formData.delete_logo" />
                  <label class="form-check-label">Delete Logo</label>
                </div>
              </div>
              <div class="col-md-4">
                <label for="top_banner_img" class="form-label">Top banner σελίδας καταστήματος</label>
                <div class="d-flex fileImg">
                  <img
                    v-if="formData.top_banner_img && typeof formData.top_banner_img.indexOf == 'function' && formData.top_banner_img.indexOf('uploads') !== -1"
                    :src="`/storage/${formData.top_banner_img}`" width="38" height="38" /><input type="file"
                    class="form-control" @change="handleFileChange('top_banner_img', $event)" />
                </div>
                <div class="form-check">
                  <input type="checkbox" class="form-check-input" v-model="formData.delete_top_banner_img" />
                  <label class="form-check-label">Delete Top Banner</label>
                </div>
              </div>
              <div class="col-md-4">
                <label for="top_offer_banner_img" class="form-label">Banner προσφορών στην σελίδα του
                  καταστήματος</label>
                <div class="d-flex fileImg">
                  <img
                    v-if="formData.top_offer_banner_img && typeof formData.top_offer_banner_img.indexOf == 'function' && formData.top_offer_banner_img.indexOf('uploads') !== -1"
                    :src="`/storage/${formData.top_offer_banner_img}`" width="38" height="38" /><input type="file"
                    class="form-control" @change="handleFileChange('top_offer_banner_img', $event)" />
                </div>
                <div class="form-check">
                  <input type="checkbox" class="form-check-input" v-model="formData.delete_top_offer_banner_img" />
                  <label class="form-check-label">Delete Offer Banner</label>
                </div>
              </div>
            </div>

            <div class="row mt-2">

            </div>

            <!-- Business Description and Hours -->
            <div class="row mt-2">
              <div class="col-md-6">
                <label for="business_descr" class="form-label">Περιγραφή επιχείρησης</label>
                <textarea class="form-control" v-model="formData.business_descr"></textarea>
              </div>
              <div class="col-md-6">
                <label for="open_hours" class="form-label">Ωράριο λειτουργίας</label>
                <textarea class="form-control" v-model="formData.open_hours"></textarea>
              </div>
            </div>

            <!-- Address and Zipcode -->
            <div class="row mt-2">
              <div class="col-md-6">
                <label for="Address" class="form-label">Διεύθυνση</label>
                <textarea rows="1" class="form-control" v-model="formData.Address"></textarea>
              </div>
              <div class="col-md-6">
                <label for="Zipcode" class="form-label">ΤΚ</label>
                <textarea rows="1" class="form-control" v-model="formData.Zipcode"></textarea>
              </div>
            </div>

            <!-- Contact Information -->
            <div class="row mt-2">
              <div class="col-md-6">
                <label for="Contactperson" class="form-label">Επικοινωνία</label>
                <input type="text" class="form-control" v-model="formData.Contactperson" />
              </div>
              <div class="col-md-6">
                <label for="Email" class="form-label">Email</label>
                <input type="text" class="form-control" v-model="formData.Email" />
              </div>
            </div>

            <!-- Phone Information -->
            <div class="row mt-2">
              <div class="col-md-6">
                <label for="Phone" class="form-label">Τηλ.</label>
                <input type="text" class="form-control" v-model="formData.Phone" />
              </div>
              <div class="col-md-6">
                <label for="mobile" class="form-label">Κινητό</label>
                <input type="text" class="form-control" v-model="formData.mobile" />
              </div>
            </div>

            <!-- Social Links -->
            <div class="row mt-2">
              <div class="col-md-4">
                <label for="Webpage_link" class="form-label">Web</label>
                <input type="text" class="form-control" v-model="formData.Webpage_link" />
              </div>
              <div class="col-md-4">
                <label for="Facebook_link" class="form-label">Facebook</label>
                <input type="text" class="form-control" v-model="formData.Facebook_link" />
              </div>
              <div class="col-md-4">
                <label for="instagram_link" class="form-label">Instagram</label>
                <input type="text" class="form-control" v-model="formData.instagram_link" />
              </div>
            </div>

            <!-- Category Selection with Select2 (Multiple) -->
            <div class="row mt-2">
              <div class="col-md-6">
                <label for="business_category_id" class="form-label">Κατηγορίες</label>
                <select id="business_category_id" class="form-control" multiple="multiple">
                  <!-- Options dynamically handled by Select2 -->
                </select>
              </div>
              <div class="col-md-6">
                <label for="Subcategory" class="form-label">Υποκατηγορία</label>
                <input type="text" class="form-control" v-model="formData.Subcategory" />
              </div>
            </div>
            <!-- Latitude and Longitude -->
            <div class="row mt-2">
              <div class="col-md-6">
                <label for="Lat" class="form-label">Latitude</label>
                <input type="text" class="form-control" v-model="formData.Lat" />
              </div>
              <div class="col-md-6">
                <label for="Lon" class="form-label">Longitude</label>
                <input type="text" class="form-control" v-model="formData.Lon" />
              </div>
            </div>
            <!-- Save Button -->
            <div class="row mt-2">
              <div class="col-md-12 d-flex justify-content-end">
                <button type="submit" class="btn btn-primary mt-2">Αποθήκευση</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, nextTick } from 'vue';
import { useToast } from 'vue-toastification';
import apiClient from '@/services/api';
import { getCurrentBusinessPartnerData, updateCurrentBusinessPartnerData } from '../services/business-partners';
import axios from 'axios'; // Ensure Axios is imported for the API call
import 'select2';
import 'select2/dist/css/select2.css';

export default {
  name: 'BusinessPartnerProfile',
  setup() {
    const formData = ref({
      brandname: '',
      trademark: '',
      Vatcode: null,
      Logo: null,
      delete_logo: false,
      top_banner_img: null,
      delete_top_banner_img: false,
      top_offer_banner_img: null,
      delete_top_offer_banner_img: false,
      business_descr: '',
      open_hours: '',
      Address: '',
      Zipcode: '',
      Contactperson: '',
      Email: '',
      Phone: '',
      mobile: '',
      Webpage_link: '',
      Facebook_link: '',
      instagram_link: '',
      business_category_id: [],
      Subcategory: '',
      Lat: '',
      Lon: '',
    });

    const toast = useToast();

    const fetchCategories = async () => {
      try {
        const response = await apiClient.get('getbusinesscategories');
        const categories = response.data.data;

        // Dynamically append categories to the select element
        const selectElement = document.getElementById('business_category_id');
        categories.forEach(category => {
          const option = document.createElement('option');
          option.value = category.id;
          option.text = category.title;
          selectElement.appendChild(option);
        });

        // After appending the categories, initialize Select2
        initializeSelect2();
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    const initializeSelect2 = () => {
      $('#business_category_id').select2({
        multiple: true,
      });

      // Listen for changes in Select2 and update formData
      $('#business_category_id').on('change', function () {
        formData.value.business_category_id = $(this).val();
      });
    };

    const setCategorySelections = async (bcatIds) => {
      await nextTick(); // Ensure DOM update
      $('#business_category_id').val(bcatIds).trigger('change');
    };

    const fetchFormData = async () => {
      const data = await getCurrentBusinessPartnerData();
      if (data.data.success) {
        formData.value = { ...formData.value, ...data.data.data };

        // Collect category IDs to select
        let bcatIds = [];
        if (data.data.data.business_categories) {
          bcatIds = data.data.data.business_categories.map((category) => category.id);
        }

        // Set selected categories once the form data is fetched
        await setCategorySelections(bcatIds);
      }
    };

    const saveForm = async () => {
      const formDataObj = new FormData();

      // Append all fields to FormData
      formDataObj.append('brandname', formData.value.brandname);
      formDataObj.append('trademark', formData.value.trademark);
      formDataObj.append('Vatcode', formData.value.Vatcode);
      formDataObj.append('business_descr', formData.value.business_descr);
      formDataObj.append('open_hours', formData.value.open_hours);
      formDataObj.append('Address', formData.value.Address);
      formDataObj.append('Zipcode', formData.value.Zipcode);
      formDataObj.append('Contactperson', formData.value.Contactperson);
      formDataObj.append('Email', formData.value.Email);
      formDataObj.append('Phone', formData.value.Phone);
      formDataObj.append('mobile', formData.value.mobile);
      formDataObj.append('Webpage_link', formData.value.Webpage_link);
      formDataObj.append('Facebook_link', formData.value.Facebook_link);
      formDataObj.append('instagram_link', formData.value.instagram_link);
      formDataObj.append('Subcategory', formData.value.Subcategory);
      formDataObj.append('Lat', formData.value.Lat);
      formDataObj.append('Lon', formData.value.Lon);

      // Handle multiple category selections
      formData.value.business_category_id.forEach((categoryId) => {
        formDataObj.append('business_category_id[]', categoryId);
      });

      // Handle file uploads and deletions
      if (formData.value.Logo && formData.value.delete_logo !== true) {
        formDataObj.append('Logo', formData.value.Logo);
        formDataObj.append('delete_logo', false);
      } else if (formData.value.delete_logo) {
        formDataObj.append('delete_logo', true);
      }

      if (formData.value.top_banner_img && formData.value.delete_top_banner_img !== true) {
        formDataObj.append('top_banner_img', formData.value.top_banner_img);
        formDataObj.append('delete_top_banner_img', false);
      } else if (formData.value.delete_top_banner_img) {
        formDataObj.append('delete_top_banner_img', true);
      }

      if (formData.value.top_offer_banner_img && formData.value.delete_top_offer_banner_img !== true) {
        formDataObj.append('top_offer_banner_img', formData.value.top_offer_banner_img);
        formDataObj.append('delete_top_offer_banner_img', false);
      } else if (formData.value.delete_top_offer_banner_img) {
        formDataObj.append('delete_top_offer_banner_img', true);
      }

      // Submit the form data
      const response = await updateCurrentBusinessPartnerData(formDataObj);

      // Reset file inputs and checkboxes
      formData.value.Logo = null;
      formData.value.delete_logo = false;
      formData.value.top_banner_img = null;
      formData.value.delete_top_banner_img = false;
      formData.value.top_offer_banner_img = null;
      formData.value.delete_top_offer_banner_img = false;

      if (response.data.success) {
        // Successfully saved
        toast.success(response.data.message || 'Τα δεδομένα αποθηκεύτηκαν επιτυχώς!');
        $("input[type='file']").each(function () {
          $(this).val('').trigger('change');
        });
        $(".form-check-input").each(function () {
          if ($(this).is(":checked")) {
            $(this).prop('checked', false);
          }
        });
        fetchFormData();
      } else {
        toast.error(response.data.message || 'Αποτυχία αποθήκευσης');
      }
    };


    const handleFileChange = (field, event) => {
      const file = event.target.files[0];
      formData.value[field] = file;
    };

    onMounted(async () => {
      await fetchCategories(); // Fetch and append categories first
      await fetchFormData(); // Fetch and populate the form data on mount
    });

    return {
      formData,
      saveForm,
      handleFileChange,
    };
  },
};
</script>



<style scoped>
/* Custom styles (if any) */
.fileImg img {
  object-fit: cover;
}

.select2-container--default {
  background-color: #eeeeee !important;
  border: 0px;
}
</style>